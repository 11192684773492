import React from "react";

export default function Login() {
  return (
    <div>
      <section className="log-reg">
        <div className="overlay pb-120">
          <div className="container">
            <div className="top-head-area">
              <div className="row d-flex align-items-center">
                <div className="col-sm-5 col">
                  <a className="back-home text-black" href="/">
                  <i class="fa fa-arrow-left me-2" aria-hidden="true"></i>

                    Back To Home
                  </a>
                </div>
                <div className="col-sm-5 col">
                  <a href="/">
                    <img
                      src="assets/img/new-img/logo.svg"
                      className="logo"
                      alt="logo"
                      style={{ width: "80%" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-5 text-center">
                <div className="form-box">
                  <h4>Sign In</h4>
                  <p className="dont-acc">
                    Don't have an account? <a>Register</a>
                  </p>

                  <div className="tab-content mt-5">
                    <div
                      className="tab-pane fade active show"
                      id="business"
                      role="tabpanel"
                      aria-labelledby="business-tab"
                    >
                      <form action="#">
                        <div className="row">
                          <div className="col-12">
                          <div className="text-start mb-2">
                              <label htmlFor="" className="text-start">
                              Email
                              </label>
                            </div>
                            <div className="single-input d-flex align-items-center">
                              <input type="email" placeholder=" email" />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="text-start mb-2">
                              <label htmlFor="" className="text-start">
                                password
                              </label>
                            </div>
                            <div className="single-input d-flex align-items-center">
                              <input
                                type="password"
                                className="passInput"
                                placeholder="Password"
                              />
                              <img
                                className="showPass"
                                src="assets/img/show-hide.png"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="btn-area">
                          <a className="cmn-btn" > Coming Soon...</a>
                        </div>
                      </form>
                      <div className="form-bottom">
                        <div className="continue">
                          <p>Or continue with</p>
                        </div>
                        <div className="login-with d-flex align-items-center">
                          <a href="javascript:void(0)">
                            <img src="assets/img/google.png" alt="image" />
                          </a>
                          <a href="javascript:void(0)">
                            <img src="assets/img/fb.png" alt="image" />
                          </a>
                        </div>
                        <div className="forget-pw">
                          <a href="#">
                            Forgot your password?
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
