import React from "react";
import Slider from "react-slick";

export default function Testimonials() {
  var settings = {
    dots: false,
    // infinite: true,
    centerMode: true,
    infinite: true,
    // autoplay: true,
    // speed: 3000,
    // autoplaySpeed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div>
      <section className="testimonials-index3 index4" id="testimonials">
        <div className="overlay pt-90 pb-90">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <div className="section-header text-center">
                  <h4 className="title mb-2">Testimonials</h4>
                  <p>
                    DeFiiPe has provided a great experience to all customers.
                    Some of our clients have shared their kind words for us
                  </p>
                </div>
              </div>
            </div>
            {/* className="testimonials-carousel-index4" */}
            <div >
              <Slider {...settings}>
                <div>
                  <div className="single text-center">
                    <div className="single-item">
                      <div className="d-flex justify-content-center text-center mb-4">
                        <img
                          src="assets/img/new-img/plugins-1.png"
                          style={{ borderRadius: "50%" }}
                          alt="image"
                        />
                      </div>
                      <ul className="d-flex star-area align-items-center justify-content-center">
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                      <h6 className="m-2">Plugins are fantastic</h6>
                      <p>
                        DeFiiPe’s plugins have made everything easy for me. I
                        especially loved the auto withdrawal features. It has
                        the best crypto wallet feature.
                      </p>
                      <div className="profile mb-0">
                        <h6 class="m-2 mt-0">Anthony Bevor </h6>
                        <p className="m-2 mb-0">Customer </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item">
                      <div className="d-flex justify-content-center text-center mb-4">
                        <img
                          src="assets/img/new-img/affordabl.png"
                          style={{ borderRadius: "50%" }}
                          alt="image"
                        />
                      </div>
                      <ul className="d-flex star-area align-items-center justify-content-center">
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                      <h6 className="m-2">Affordable & easy </h6>
                      <p>
                        DeFiiPe’s features are widely useful with it coming in
                        affordable pricing as well as easy integrations Loved
                        it, good product .
                      </p>
                      <div className="profile mb-0">
                        <h6 class="m-2 mt-0">Mark Beneth</h6>
                        <p className="m-2 mb-0">Customer </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item">
                      <div className="d-flex justify-content-center text-center mb-4">
                        <img
                          src="assets/img/new-img/best-crypto.png"
                          style={{ borderRadius: "50%" }}
                          alt="image"
                        />
                      </div>
                      <ul className="d-flex star-area align-items-center justify-content-center">
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                      <h6 className="m-2">Best crypto payment solution</h6>
                      <p>
                        I knew I came across the best crypto payment solution on
                        the first day when I started using DeFiiPe for my
                        business! Incredible work.
                      </p>
                      <div className="profile mb-0">
                        <h6 class="m-2 mt-0">Andy Rodriguez</h6>
                        <p className="m-2 mb-0">Customer </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item">
                      <div className="d-flex justify-content-center text-center mb-4">
                        <img
                          src="assets/img/new-img/simple-useful.png"
                          style={{ borderRadius: "50%" }}
                          alt="image"
                        />
                      </div>
                      <ul className="d-flex star-area align-items-center justify-content-center">
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                      <h6 className="m-2">Simple & useful</h6>
                      <p>
                        DeFiiPe has been useful for me and it is the product I
                        have been looking for since a year when I started my
                        business. Thanks DeFiiPe 🙏
                      </p>
                      <div className="profile mb-0">
                        <h6 class="m-2 mt-0">Miller Juvenile </h6>
                        <p className="m-2 mb-0">Customer </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
