import React from 'react'

export default function LittleIdeas() {
  return (
    <div>
        <section className="features-inner">
  <div className="overlay pt-90 pb-90">
    <div className="container">
      
      <div className="row d-flex justify-content-between">
        <div className="col-lg-6 d-flex justify-content-center">
          <div className="lef">
            <img src="assets/img/new-img/our-approach-on-crypto-payments.png" alt="image" />
          </div>
        </div>
        
        <div className="col-lg-6 d-flex align-items-center">
          <div className="right-area">
          <h4 className="title mb-2">Our Approach on  <span className="text-Purple"> Crypto Payments</span></h4>
            <p className='mb-4'>DeFiiPe makes it possible to upgrade from traditional payments to crypto payments in an easy method. </p>
            <ul>
              <li>
                <a className="mdr" >
                  <div className="">
                    <img src="assets/img/new-img/easy.png" alt="image" className='me-2' />
                  </div>
                  Easy integration of payment platforms.

                </a>
              </li>
              <li>
                <a className="mdr" >
                  <div className="">
                  <img src="assets/img/new-img/support-2.png" alt="image" className='me-2' />
                  </div>
                  Support for hundreds of currencies.
                </a>
              </li>
              <li>
                <a className="mdr" >
                  <div className="">
                  <img src="assets/img/new-img/submission3.png" alt="image" className='me-2' />
                  </div>
                  No KYC or Bank Submission required.
                </a>
              </li>
              <li>
                <a className="mdr" >
                  <div className="">
                  <img src="assets/img/new-img/global4.png" alt="image" className='me-2' />
                  </div>
                  Global access regardless of geographical location. 
                </a>
              </li>
            
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
