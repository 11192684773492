import React from "react";

export default function PrivacyPolicyMain() {
  return (
    <div>
      <div className="dashboard-section transactions recipients p-0 m-0 w-100">
        <div className="overlay pt-120 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-header mb-4">
                  <h4 className="title text-center">
                    Privacy <span className="text-Purple"> Policy </span>
                  </h4>
                  <p>
                    This Privacy Policy (the "Arrangement") lets ("you" or
                    "Client") know how DeFiiPe ("we" or "us" or
                    "Organization") gathers, forms, holds and offers data gave
                    to us to use on the site, online interface or pages worked
                    or possessed by the Company or its members (on the whole
                    "Organization Websites"), or the DeFiiPe Payment Gateway
                    ("Payment Gateway") to give you different installment
                    benefits (the "Administrations").
                  </p>
                  <p className="mt-2">
                    The Company is focused on the protection privileges of
                    Users, clients, and different guests. This Policy covers
                    your decisions concerning the sharing of your data on the
                    Company Websites and Payment Gateway and keeping in mind
                    that utilizing the Services offered by the Company.
                  </p>
                </div>
                <div>
                  <h5 className="text-black mb-2">
                    We may process information from the following types of
                    users:
                  </h5>
                  <ul className="ms-0 ms-md-4" style={{ listStyle: "number" }}>
                    <li>Visitors Of Our Sites</li>
                    <li>
                      Merchants, who sign up for our Services of payment
                      gateway.
                    </li>
                    <li>
                      DeFiiPe wallet holders, who are using our crypto
                      wallets.
                    </li>
                  </ul>
                  <h5 className="text-Purple mb-2 mt-4">
                    What information do we collect?
                  </h5>
                  <h6 className="text-black m-2 mt-3 ">
                    1. Visitors Of Website
                  </h6>
                  <ul className="ms-0 ms-md-4" style={{ listStyle: "disc" }}>
                    <li>
                      Technical information, including IP address; the type of
                      browser, devices, and operating systems you use, the pages
                      you visit and the features you use; access dates and
                      times;
                    </li>
                    <li>
                      Email address (e.g., when you subscribe to our blog)
                    </li>
                    <li>Full name (e.g., when you submit a support request)</li>
                  </ul>
                  <h6 className="text-black m-2 mt-3 ">
                    2. Merchants who have signed up for our services.
                  </h6>
                  <ul className="ms-0 ms-md-4" style={{ listStyle: "disc" }}>
                    <li>Full name of the business and merchant</li>
                    <li>
                      Merchant email address ( it can be business Email Id)
                    </li>
                    <li>Merchants Website URL and IP address</li>
                    <li>Business or merchants phone number</li>
                  </ul>
                  <p>
                    We may use this information for risk management purposes to
                    comply with Singapore laws and regulations.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">
                    3. DeFiiPe Wallet Holder
                  </h6>
                  <ul className="ms-0 ms-md-4" style={{ listStyle: "disc" }}>
                    <li>Email address</li>
                    <li>Full name</li>
                    <li>Phone Number</li>
                  </ul>
                  <p>
                    DeFiiPe may collect some additional information in efforts
                    to combat fraud, illegal activity, or comply with laws and
                    regulations.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">Cookies Used by Us </h6>
                  <p>
                    We may collect information about your activities on our
                    sites via cookies. We may not allow third parties to use
                    cookies on our sites. The information collected by cookies
                    will not be shared. Information collected by cookies may be
                    used on this site or on other websites or services,
                    including those that are operated by us. Other parties are
                    not allowed to collect personally identifiable information
                    about an individual consumer’s online activities over time
                    and across different websites when a consumer uses our
                    websites or online services.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">
                    Reason for Collecting Personal Information
                  </h6>
                  <p>
                    We use the information collected by us to operate our
                    business, provide better our Services, and in some cases if
                    there is any external government or law force for the
                    required data, and to provide you with a smooth, efficient,
                    and customized experience.
                  </p>
                  <ul className="ms-0 ms-md-4" style={{ listStyle: "disc" }}>
                    <li>Provide better services</li>
                    <li>To inform you of any updates</li>
                    <li>To solve any dispute related to our services.</li>
                    <li>To protect business and to work according to law.</li>
                    <li>Risk management</li>
                  </ul>
                  <h6 className="text-black m-2 mt-3 ">
                    Protection & Storage Personal Information
                  </h6>
                  <p>
                    We take the security of information very seriously by using
                    administrative, technical, and physical security to
                    safeguard personal information in our possession. However,
                    we do not guarantee the security of the information that we
                    collect & store. Certainly, we do not share information with
                    any third parties but in any scenario of governmental or law
                    enforcement pressure, we might have to share data with them.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">
                    Personal Data Protection Act
                  </h6>
                  <p>
                    DeFiiPe, we take our responsibilities under the Personal
                    Data Protection Act (PDPA) of Singapore. The PDPA covers
                    personal data stored in electronic and non-electronic
                    formats. It comprises various requirements governing the
                    collection, use, disclosure, and care of personal data in
                    Singapore.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">
                    Personal Data Deletion
                  </h6>
                  <p>
                    Right to be forgotten – This right is also known as the
                    "right to erasure." It is an individual’s right to have
                    personal information erased or to prevent processing in
                    specific circumstances.
                  </p>
                  <h6 className="text-black m-2 mt-3 ">Grievance or Support</h6>
                  <p>
                    Any complaints, abuse, or concerns with regards to content
                    and or comment or breach of these terms shall be immediately
                    informed via in writing or through email on &nbsp;
                    <a href="mailto:support@DeFiiPe.io"  style={{ color: "blue" }}>
                      {"  support@DeFiiPe.io"}
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
