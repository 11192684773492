import React from "react";
import Slider from "react-slick";

export default function Hero() {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    arrows: false,
    dots: false,
    
    autoplay: true,
    speed: 2000,

    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
  };
  return (
    <div>
      <section className="market-solutions">
        <div className="overlay pt-50 pb-100">
          <div className="container pt-0 pt-md-5">
            <div className="row cus-mar align-items-center">
              <div className="col-lg-7 d-flex align-items-center">
                <div className="section-header">
                  <h2 className="title text-black">
                    <span class="text-Purple"> Pay & Accept </span> Crypto{" "}
                    <br /> Payments with &nbsp; <br />
                    <span class="text-Purple"> DeFiiPe</span>
                  </h2>
                  <p className="text-black">
                    Unlock broader business growth opportunities by integrating
                    DeFiiPe’s crypto <br /> payment gateway.
                  </p>
                  <div className="d-flex flex-wrap ">
                    {/* <a href="#" className="cmn-btn mx-1 me-0 me-sm-2 mt-2">
                      Join
                    </a> */}
                    <a href="/plugins" className="cmn-btn  mt-2">
                      Know More{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="slider-container">
                  <Slider {...settings}>

                    <div>
                      <div
                        className="single"
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div
                          className="single-item pt-3"
                          style={{
                            height: "300px",
                            borderRadius: "20px",
                            border: "1px solid"
                          }}
                        >
                          <div className="d-flex justify-content-between ">
                            <h6 className="mb-3">Deposit Address</h6>
                            <p className="mb-3 text-op text-green">01:32:56<i class="fa-solid fa-circle-question ms-2"></i></p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                src="assets/img/new-img/bitcoin-logo.svg"
                                style={{ width: "100px" }}
                                alt=""
                              />
                              <p className="mt-2">
                              1PyMb...XXXXX
                                <i class="fa-regular fa-copy mx-2 me-3 c-pointer"></i>
                                <i class="fa-solid fa-arrow-up-right-from-square c-pointer"></i>
                              </p>
                            </div>
                            <div>
                              <img
                                src="assets/img/new-img/dummy-qr-code.png"
                                style={{ position: "relative", zIndex: "99" }}
                                alt=""
                              />
                              <div className="bor-ani-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="single"
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div
                          className="single-item pt-3"
                          style={{
                            height: "300px",
                            border: "4px solid #22c55e",
                            background: "rgb(223 255 235)",
                            borderRadius: "20px",
                          }}
                        >
                          <div className="d-flex justify-content-between ">
                            <h6 className="mb-3">Invoice #btcXXXX</h6>
                            {/* <p className="mb-3 text-op">Final Amount $100</p> */}
                          </div>
                          <div className="text-center d-flex justify-content-center">
                            <img
                              src="assets/img/new-img/thank_you_bg.png"
                              className="hero-img-roted"
                              style={{ height: "140px" }}
                              alt=""
                            />
                            <i
                              class="fa-solid fa-check xxlr text-white"
                              style={{ position: "absolute", top: "43%" }}
                            ></i>
                          </div>
                          <h5
                            className="text-center mt-2"
                            style={{ color: "#22c55e" }}
                          >
                            Thank You!
                          </h5>
                          <p className="text-center">
                            The payment has been received and processed.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="single"
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div
                          className="single-item pt-3"
                          style={{
                            height: "300px",
                            borderRadius: "20px",
                            border: "1px solid",
                          }}
                        >
                          <h5 className="mb-3">Select payment Method</h5>
                          <div className="d-flex align-items-center gap-2">
                            <div className="hei">
                              <div className="h-bg">
                                {" "}
                                <div className="hero-e"></div>
                              </div>
                            </div>{" "}
                            <p>Using Crypto</p>
                          </div>
                          <hr />
                          <div
                            className="d-flex align-items-center gap-2"
                            style={{ filter: "blur(2px)" }}
                          >
                            <div className="hei">
                              <div className="h"></div>
                            </div>{" "}
                            <p>Using Crypto</p>
                          </div>
                          <hr />
                          <div
                            className="d-flex align-items-center gap-2"
                            style={{ filter: "blur(2px)" }}
                          >
                            <div className="hei">
                              <div className=""></div>
                            </div>{" "}
                            <p>Using Crypto</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="single"
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <div
                          className="single-item pt-3"
                          style={{
                            height: "300px",
                            borderRadius: "20px",
                            border: "1px solid",
                          }}
                        >
                          <div className="d-flex justify-content-between ">
                            <h6 className="mb-3">Select Crypto</h6>
                            <p className="mb-3 text-op">Final Amount $100</p>
                          </div>
                          <div className="border-et d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center  gap-2">
                              <img
                                src="assets/img/new-img/n1_48.png"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                }}
                                alt="image"
                              />
                              <h6>Network </h6>
                            </div>
                            <div>
                              <p className="smr">1</p>
                              <p className="smr m-0">NET</p>
                            </div>
                          </div>

                          <div
                            className="border-et d-flex justify-content-between align-items-center aj mt-3"
                            style={{
                              background: "#efd4ff",
                              borderColor: "#9f24e7",
                            }}
                          >
                            <div className="d-flex align-items-center  gap-2">
                              <img
                                src="assets/img/new-img/btc (1).png"
                                style={{ height: "30px", width: "30px" }}
                                alt="image"
                              />
                              <h6>Bitcoin</h6>
                            </div>
                            <div>
                              <p className="smr">0.001</p>
                              <p className="smr m-0">BTC</p>
                            </div>
                          </div>
                          <div className="border-et d-flex justify-content-between align-items-center  mt-3">
                            <div className="d-flex align-items-center  gap-2">
                              <img
                                src="assets/img/new-img/doge.png"
                                style={{ height: "30px", width: "30px" }}
                                alt="image"
                              />
                              <h6>Dogecoin</h6>
                            </div>
                            <div>
                              <p className="smr">15 </p>
                              <p className="smr m-0">DOGE</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
