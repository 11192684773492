import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../Components/Coman/Sidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../../Components/Coman/Pagination';

export default function X1income() {
    const { account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "x1",
                submethod: "getbyid",
                // address: "0x6652e765ccd969816503c40da6e91816517b8691",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);


    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                            <div className="row">
                                <div className="col-lg-5 col-md-4">
                                    <h4>1X Reward</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    {/* <div className="top-items">
                                        <h6>Direct Referral</h6>
                                    </div> */}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Date</th>
                                                     <th scope="col">Pool 50</th>
                                                    <th scope="col">Total Stake</th>
                                                    <th scope="col">Total Reward (%)</th>
                                                    <th scope="col">Total Users</th>
                                                    <th scope="col">Reward</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td className="text-center" colSpan={10}>
                                                        {isLoading ? "Data is loading" : ""}
                                                    </td>
                                                </tr>
                                                {!isLoading ? (
                                                    !data.length ? (
                                                        <tr className="text-center">
                                                            <td className="text-center" colSpan={10}>
                                                                <span className="w-100">No data found</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                {data.map((ele, i) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {ele.id}
                                                            </td>
                                                            <td>
                                                                ${ele.t_staking}
                                                            </td>
                                                            <td>
                                                                {ele.createdAt}
                                                            </td>
                                                            <td>
                                                               $ {ele.pkg_amount}
                                                            </td>
                                                            <td>
                                                               $ {ele.pkg_pkginc} ({ele.income_per}%)
                                                            </td>
                                                            <td>
                                                                {ele.t_users}
                                                            </td>
                                                            <td>
                                                                $ {ele.income}
                                                            </td>
                                                            {/* <td>
                                                                {ele.status ? (
                                                                    <div className="text-success">
                                                                        Complete
                                                                    </div>
                                                                ) : (
                                                                    <div className="text-danger">
                                                                        Running
                                                                    </div>
                                                                )}
                                                            </td>
                                                          */}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pages={pages}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}