import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Components/Coman/Sidebar";
// import CountdownTimer from "./CountdownTimer";
import axios from 'axios';
import { ProjectContext } from "../ProjectContext";
import { useParams } from "react-router-dom";

export default function PoolEntry() {
  const {
    account,
    balance,
    toastSuccess,
    library,
    dbuser,
    tokenrate, formatAddress,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)

  const [data, setdata] = useState([]);
  const [sel_data, setsel_data] = useState([]);
  const [exist_slots, setexist_slots] = useState([]);
  const [t_amount, sett_amount] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    console.log("dassd", t_amount);
    if (!t_amount) {
      toastError("Select slots");
      settrdone(false);
      return;
    }
    // if (Number(balance) < Number(total_tokens)) {
    //   toastError("Insufficient balance");
    //   return;
    // }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    var data = await getudata(referral);
    if (data.data.error) {
      console.log("data.data.errordata.data.error", data.data.error);

      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    // if (dbuser !== null && dbuser.last_staking > amount) {
    //     toastError(`Stake ${dbuser.last_staking} or more`);
    //     settrdone(false);
    //     return;
    // }
    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );

    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = null
        if (dbuser === null) {
          console.log("no dbyser", account,
            referral,
            sel_data);
          stk = await contract.membership(account,
            referral,
            sel_data
          );
        } else {
          stk = await contract.upgrade(account,
            sel_data
          );
        }
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: t_amount,
            slots: sel_data
          })
          .then((res) => {
            console.log("res", res.data);
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        getuserDB();
        setsel_data([])
        settrdone(false);
        // navigate("/dashboard");

      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }

    }
    // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
    if (Number(ethers.utils.formatEther(allowance)) < t_amount) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  const getData = async () => {

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "slot",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  // function adddata(item) {
  //   console.log(item);
  //   var temparray = []
  //   var famt = 0
  //   for (let index = 1; index <= item; index++) {
  //     temparray.push(index)
  //     famt = famt + data[index - 1].amount
  //   }
  //   setsel_data(temparray)
  //   sett_amount(famt)
  // }
  function adddata(item) {
    console.log(item);
    var temparray = [];
    var famt = 0;
    var newData = []; // new array to store both array data

    for (let index = 1; index <= item; index++) {
      if (!exist_slots.includes(index)) { // check if index is not in exist_slots
        temparray.push(index);
        famt = famt + data[index - 1].amount;
        newData.push(data[index - 1]); // add data to new array
      }
    }

    setsel_data(temparray);
    sett_amount(famt);
    console.log("temparra", temparray);
    console.log("famt", famt);
    console.log("new dadas", newData);

    return newData;
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    console.log(sel_data)
  }, [sel_data]);
  useEffect(() => {
    if (dbuser !== null) {
      console.log("dbuser", dbuser);

      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
        setexist_slots(dbuser.slots)
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [dbuser, account]);



  return (
    <>
      <Sidebar />
      <section
        className="dashboard-section body-collapse pay step deposit-money withdraw-money"
        style={{ background: "#F7F9FD" }}
      >
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5> Purchase Membership</h5>
              </div>

              <div className="row pb-120  justify-content-center">
                <div className="col-12">
                  <div className="table-area">
                    <div className="head-item">
                      {/* <h6>Linked Payment system</h6> */}
                    </div>
                    <div className="row align-items-start">
                      <div className="col-xl-8">
                        <div className="card-area d-flex  flex-wrap w-100 ">
                          {data.map((e) => {
                            return (
                              <div className="single-card" style={{ opacity: `${exist_slots.includes(e.number) ? "0.5" : '1'}` }}>
                                <input
                                  type="checkbox"
                                  name="cardSelect"
                                  checked={sel_data.includes(e.number)}
                                  onChange={() => adddata(e.number)}
                                  id={`slot${e.amount}`}
                                />
                                <label htmlFor={`slot${e.amount}`}>
                                  <span className="wrapper" />
                                  <img
                                    src={`/assets/img/slote/dollar${e.amount}.png`}
                                    alt="image"
                                    style={{ width: "180px" }}
                                  />
                                </label>
                                <h6 className="text-uppercase ">{e.package}</h6>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="col-xl-4 mt-3 mt-md-0">
                        <form action="#">
                          <div className="row justify-content-center p-0">
                            <div
                              className="col-xl-12 p-4"
                              style={{
                                background: "#F8F8FF",
                                borderRadius: "20px",
                                boxShadow: "3px 3px 20px 0px #b0aeae",
                              }}
                            >
                              <div className="exchange-content">
                                <div className="send-banance">
                                  <span className="mdr">Referral Address</span>
                                  {dbuser === null ?
                                    <input
                                      placeholder={'Enter referral address'}
                                      type="text"
                                      value={referral}
                                        readOnly
                                    //   onChange={(e) => { setreferral(e.target.value); }}
                                    /> : <input
                                      type="text"
                                      value={referral}
                                    />}

                                  <span className="mdr">Amount </span>
                                  <h3>${t_amount}</h3>
                                  <p className="mt-3">
                                    Available Balance<b>${balance}</b>
                                  </p>
                                  <div className="mt-3">
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        id="Basic"
                                        style={{ width: "17px" }}
                                        name="fav_language"
                                        onChange={() => adddata(6)}
                                      />
                                      <label htmlFor="Basic" className="ms-2">
                                        Basic
                                      </label>
                                      <br />
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        id="advance"
                                        style={{ width: "17px" }}
                                        name="fav_language"
                                        onChange={() => adddata(9)}
                                      />
                                      <label htmlFor="advance" className="ms-2">
                                        Advance
                                      </label>
                                      <br />
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        id="premium"
                                        style={{ width: "17px" }}
                                        name="fav_language"
                                        onChange={() => adddata(12)}
                                      />
                                      <label htmlFor="premium" className="ms-2">
                                        Premium
                                      </label>
                                    </div>
                                  </div>
                                  {hash ?
                                      <div className=" style-border2 text-center">
                                        <span>Tnx Hash:</span>
                                        <span className="text-break">#:{hash}</span>
                                      </div> : ''}
                                  <div className="footer-area mt-3 ">
                                    {/* <a href="#">Previous Step</a> */}

      
                                    {trdone ? (
                                      <img
                                        className=" bd-gradient-btn rounded-pill w-10"
                                        src="/loader.gif"
                                        alt="loading"
                                        style={{ width: '100px' }}
                                      />
                                    ) : (
                                      <a href="#" className="active" onClick={() => onDeposit()}>
                                        Purchase
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}