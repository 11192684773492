import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Components/Coman/Sidebar'
import CountdownTimer from './CountdownTimer'
import { ProjectContext } from '../ProjectContext';
import axios from 'axios';

export default function NewPoolEntry() {
  const {
    account,
    balance,
    toastSuccess,
    toastError,
    dbuser, dbbalance,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)

  const [type, settype] = useState(1);
  const [amount, setamount] = useState(5);

  const entry_data = async () => {
    if (amount > dbbalance) {
      toastError("Insufficient balance")
      return
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "pool",
        submethod: "insert",
        type: type,
        address: account,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        getuserDB()
        if (res.data.error) {
          toastError(res.data.error)
          return "";
        }
        toastSuccess(res.data.data)
      });
  };
  useEffect(() => {
    console.log("amount", amount);

  }, [amount])
  return (
    <div>
      <Sidebar />
      <section className="dashboard-section body-collapse pay step deposit-money withdraw-money" style={{ background: "#F7F9FD" }}>
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Booster Pool Entry</h5>
              </div>
              <div className="row pb-120  justify-content-center">
                <div className="col-12">
                  <div className="table-area">
                    <div className="head-item">
                      {/* <h6>Linked Payment system</h6> */}
                    </div>
                    <div className="card-area d-flex  flex-wrap w-100 justify-content-around">
                      <div className="single-card">
                        <input
                          type="radio"
                          defaultChecked
                          name="cardSelect"
                          checked={type === 1}
                          onChange={(() => { settype(1); setamount(5) })}
                          id="type1"
                        />
                        <label htmlFor="type1">
                          {/* <div className="time">
                            <CountdownTimer targetDate={'2024-11-15T15:00:00'} />
                          </div> */}
                          <span className="wrapper" />
                          <img
                            src="assets/img/slote/magic-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>
                      <div className="single-card">
                        <input type="radio" name="cardSelect" id="type2"
                          checked={type === 2}
                          onChange={(() => { settype(2); setamount(10) })}
                        />
                        <label htmlFor="type2">
                          <span className="wrapper" />
                          <img
                            src="assets/img/slote/earth-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>
                      <div className="single-card">
                        <input type="radio" name="cardSelect" id="type3"
                          checked={type === 3}
                          // onChange={(() => { settype(3); setamount(dbuser ? dbuser.last_global_amount : 0) })}
                          onChange={(() => { settype(3); setamount(25) })}
                        />
                        <label htmlFor="type3">
                          <span className="wrapper" />
                          <img
                            src="assets/img/slote/global-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>

                    </div>
                    <div>
                      <form action="#" >
                        <div className="row justify-content-center mt-5">
                          <div className="col-xl-7 p-4" style={{ background: "#F8F8FF", borderRadius: "20px", boxShadow: " 3px 3px 20px 0px #b0aeae" }}>
                            <div className="exchange-content">
                              <div className="send-banance">
                                <span className="mdr">You Send</span>
                                <div className="input-area">
                                  <input
                                    className="xxlr"
                                    placeholder={0}
                                    type="number"
                                    value={amount}
                                  />
                                </div>
                                <p>
                                  Available Balance<b>${dbbalance}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="footer-area mt-40">
                    {/* <a href="#">Previous Step</a> */}
                    <a type='button' className="active" onClick={() => entry_data()}>
                      Buy Membership
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
