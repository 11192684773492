import React from "react";

export default function HowDoesWork() {
  return (
    <div>
      <section className="whatinit-section" id="DeFiiPe-work">
        <div className="overlay pt-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-header text-center">
                  <h3 className="title">
                    How Does{" "}
                    <span className="text-Purple"> DeFiiPe Work? </span>
                  </h3>
                  <p>It just takes three simple steps…</p>
                </div>
              </div>
            </div>
            <div className="bottom-content pb-120 mb-3">
              <div className="row cus-mar">
                <div className="col-lg-4">
                  <div className="single-item text-center">
                    <div className="icon">
                      <div className="iconbg">
                        <img
                          src="assets/img/new-img/register.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="bottom-area text-center">
                      <h5>Register</h5>
                      <p>
                        Create an account on DeFiiPe for adding wallets and
                        processing crypto transactions. Users can continue
                        without completing KYC or adding a bank account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="single-item text-center">
                    <div className="icon">
                      <div className="iconbg">
                        <img
                          src="assets/img/new-img/setup.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="bottom-area text-center">
                      <h5>Setup</h5>
                      <p>
                        Set up your DeFiiPe account by adding some necessary
                        details. Explore our Plugins and APIs in order to accept
                        crypto payments on your website.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="single-item text-center">
                    <div className="icon">
                      <div className="iconbg">
                        <img
                          src="assets/img/new-img/use.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="bottom-area text-center">
                      <h5>Use</h5>
                      <p>
                        Done! Now you can accept cryptocurrency payments from
                        your customer. Unlock countless benefits of accepting
                        crypto payments with DeFiiPe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
