import React from "react";

export default function TermsServicesHero() {
  return (
    <div>
      <div className="dashboard-section transactions recipients p-0 m-0 w-100">
        <div className="overlay pt-120 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-header">
                  <h4 className="title text-center">
                    Terms of <span className="text-Purple"> Services </span>
                  </h4>
                  <p>
                    These of Service ("the Terms'') govern your use of the
                    products, services, or any other features, technologies, or
                    functionalities (the "Services'') provided by DeFiiPe.
                    (DeFiiPe, we, our, or us) through DeFiiPe website, API.
                    The terms "you" and "your" refer to the merchant to which we
                    will be providing the Services and the person signing below
                    or otherwise agreeing to the Terms on behalf of the
                    merchant. Please read the Terms carefully; by using the
                    Services, you agree to the Terms and confirm that you accept
                    them.
                  </p>
                </div>
                <div>
                  <h5 className="text-Purple mb-2">The Services</h5>
                  <p>
                    We are a cryptocurrency payment processor. We enable you to
                    accept cryptocurrency as payment for goods or services, and
                    process cryptocurrency payments that you receive from your
                    customer (Purchaser). We are not a crypto exchange or a
                    place to purchase or sell cryptocurrencies. Our Services are
                    only available to businesses that sell a product or services
                    or to registered charitable organizations that accept
                    donations. By using the Services, you authorize us to act as
                    your agent so we may receive, hold and disburse funds on
                    your behalf and to take any or all actions that we think are
                    necessary to provide the Services and to comply with
                    applicable law. Payment by Purchaser to DeFiiPe will be
                    considered the same as a payment made directly to you and
                    will extinguish the Purchaser's outstanding obligation, to
                    the extent of the payment.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    The gatherings concur as takes after
                  </h5>
                  <p>
                    Merchant's Capacity and Authority: - Trader speaks to and
                    warrants that the individual executing this Agreement is 18
                    years old or more seasoned, has the lawful specialist to
                    acknowledge this Agreement and that such acknowledgment will
                    tie on Merchant.
                  </p>
                  pInstallment Gateway Service:- The Service comprises an
                  installment door, a dashboard (at present found at , and the
                  Service APIs, which together empower dealers to acknowledge
                  bitcoin and other advanced monetary forms as installment from
                  buyers of merchandise or administrations. The vendor will
                  utilize the Service just as per the terms and states of this
                  Agreement, including the installment of charges. Shipper
                  approves the Company to get virtual monetary standards, trade
                  and dispense them for Merchant's benefit, and to take any
                  activities that the Company discovers important or alluring to
                  furnish the Service or to follow pertinent laws.)
                </div>
                <div>
                  <h5 className="text-Purple mb-2 mt-4">
                    1. Merchant's Account
                  </h5>
                  <h6 className="text-Purple m-2 mt-3 ">Generally </h6>
                  <p>
                    To use the Services, you must open a DeFiiPe Merchants
                    account. When you open an account, we will ask you for
                    contact information such as your name, phone number, email
                    address, and information relating to the ultimate beneficial
                    owner or the most senior individual from the organization.
                    We will also ask you for information on your business,
                    including your business's legal name, the physical address
                    of the business, and your company's website. The information
                    that you provide at the time of account opening must be
                    accurate and complete. We may also obtain information about
                    you from third parties, such as credit bureaus and identity
                    verification services. We have the right to reject your
                    account registration or to later close your DeFiiPe
                    Merchants account if you do not provide us with accurate,
                    complete, and satisfactory information.
                  </p>
                  <h6 className="text-Purple m-2 mt-3 ">
                    Guarding your Password
                  </h6>
                  <p>
                    You will choose a password when registering your account.
                    You are responsible for maintaining the confidentiality of
                    your password and account access information. You are fully
                    responsible for all activities that occur with the use of
                    your password or account. Please notify us immediately of
                    any unauthorized use of your password or account or any
                    other breach of security. If you share your password with
                    others we will not be liable for any loss that you may incur
                    as a result of someone else using your password or account,
                    either with or without your knowledge.
                  </p>
                  <h6 className="text-Purple m-2 mt-3 ">
                    Transaction Volume Limits
                  </h6>
                  pDeFiiPe imposes daily and annual transaction processing
                  limits on merchants. When you register for a DeFiiPe
                  account, you will be able to choose between a free and premium
                  plan. At DeFiiPe's discretion, and based on DeFiiPe’s risk
                  assessment of your business, transaction capabilities should
                  be decided.
                  <h6 className="text-Purple m-2 mt-3 ">
                    Use of Third-Party Services
                  </h6>
                  <p>
                    While using the DeFiiPe website or the Services, you may
                    be offered services, products, and promotions provided by
                    third parties. If you decide to use these third-party
                    services, you do so at your own risk and are solely
                    responsible for reviewing, understanding, and complying with
                    the associated terms and conditions. We expressly disclaim
                    any liability for the third-party services and are not
                    responsible for the performance of the third-party services
                    or services.
                  </p>
                  <h6 className="text-Purple m-2 mt-3 ">Security</h6>
                  <p>
                    We have implemented security measures designed to secure
                    your information from accidental loss and unauthorized
                    access, use, alteration, or disclosure. However, we cannot
                    guarantee that unauthorized persons will never gain access
                    to your information, and you acknowledge that you provide
                    your information at your own risk..
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    2. Data Collection, Privacy, and Security
                  </h5>
                  <p>
                    To provide the Services, we may share information about you
                    and your DeFiiPe account with third parties. All the
                    information that can be shared is what you give us while
                    creating a DeFiiPe merchant account. There is always
                    certain risk attached to it so kindly read our Disclaimer &
                    Risk Warning
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    3. Termination and Suspension
                  </h5>
                  <p>
                    We may terminate your account, at our discretion, upon
                    notice to you via email or phone communication. We may also
                    suspend your access to the Services if we suspect that you
                    have failed to comply with these Terms, pose an unacceptable
                    fraud risk to us, or if you provide any false, incomplete,
                    inaccurate, or misleading information. We will not be liable
                    to you for any losses that you incur in connection with our
                    closure or suspension of your account. Additionally, if your
                    merchant account is not in use for more than 1 year or so,
                    DeFiiPe has the right to suspend your account, disabling
                    processing capabilities.
                  </p>
                  <h6 className="text-Purple m-2 mt-3 ">
                    And what kind of business or website cannot get access to
                    DeFiiPe services.
                  </h6>
                  <ul className="m-3" style={{ listStyle: "disc" }}>
                    <li>
                      Any terrorist group or any Anti-human-related group or
                      businesses or website
                    </li>
                    <li>
                      Website directly or indirectly related to illegal
                      activities.
                    </li>
                    <li>Any website which is a scam (hoax)</li>
                    <li>
                      Any website that choose MLM scheme to promote their
                      business
                    </li>
                    <li>
                      Any website which promise unimaginary returns (ROI) to
                      their customer
                    </li>
                  </ul>
                  <p>
                    We may ask for more information if we find some suspicious
                    activity on your website or through your website, then there
                    will be no prior notice given to you before suspending your
                    account, or to take legal action against you and your
                    business.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">4. Fees</h5>
                  <p>
                    Merchant Fees: - There are two plans available free and
                    premium, in both of the plans there is a processing fee that
                    the merchant has to pay which is about 0.23%. And in the
                    premium plan merchants have to buy the plan for 99.99 USD.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">5. Settlement</h5>
                  <p>
                    We will verify the Purchaser’s payments and post the balance
                    to your accounting ledger, according to your preference
                    settings. You cannot receive a settlement in your local
                    currency. You assume volatility risks of the currency in
                    which you choose to settle. For example, if you choose to
                    settle in bitcoin, then you assume the volatility risk of
                    the bitcoin value.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    6. Discount Procedures
                  </h5>
                  <p>
                    There is nothing like a discount, but there is certainly an
                    affiliate commission which you can earn by giving a
                    reference of DeFiiPe to your known merchants and so, the
                    commission is about 75% of transaction fee 0.23%, which
                    means you will receive 0.17% of the transaction amount on
                    every transaction your referral makes.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">7. Charge Changes</h5>
                  <p>
                    The Company may change fees and other charges of their
                    service on occurrences to the volatility of cryptocurrencies
                    price, and it comes into effect without prior notice to the
                    merchant. Vendor's acknowledgment of another Fee Schedule,
                    or Merchant's proceeds with the utilization of the Service,
                    constitutes Merchant's acknowledgment of such changes.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    8. Intellectual Property
                  </h5>
                  <p>
                    We retain all right, title, and interest in and to the
                    Content and all of DeFiiPe’s brands, logos, and
                    trademarks. Subject to the terms and states of this
                    Agreement, DeFiiPe thus allows a Merchant constrained,
                    non-selective, non-transferable permit to use our content
                    with our services.
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    9. Confidential Information
                  </h5>
                  <p>
                    Confidential Information (the Receiving Party) from the
                    other party (the Disclosing Party) concurs: (a) to hold the
                    Disclosing Party's Confidential Information in strict
                    certainty, and utilize a similar level of care that it uses
                    to ensure the privacy of its secret data of like kind
                    (however at the very least sensible care); (b) not to
                    utilize any Confidential Information of the Disclosing Party
                    for any reason outside the extent of this Agreement; and (c)
                    aside from as generally approved by the Disclosing Party in
                    composing, to restrain access to Confidential Information of
                    the Disclosing Party to those of its and its Affiliates'
                    workers and temporary workers who require that entrance for
                    purposes reliable with this Agreement and who have consented
                    to classification arrangements with the Receiving Party
                    containing insurances no less stringent
                  </p>
                  <h5 className="text-Purple mb-2 mt-4">
                    10. Limitation of Liability
                  </h5>
                  <p>
                    The company or any of its affiliates or vendors (or any
                    officers, directors, employees or agents of the company or
                    its affiliates or vendors) be liable for any indirect,
                    incidental, consequential, special, or exemplary damages
                    (however or whenever arising), including, without
                    limitation, damages for lost revenue, lost profits,
                    anticipated profits, lost business or injury to business
                    reputation, cost of procurement of substitute services,
                    under any theory of liability or cause of action whether in
                    tort (including negligence), contract or otherwise,
                    regardless of whether it has been advised of the possibility
                    of such damages;
                  </p>
                  <p className="mt-2">
                    The company's total liability to the merchant, whether
                    arising in tort (including negligence), contract or
                    otherwise, under this agreement or concerning any the
                    company products or services, exceed the aggregate
                    compensation the company received for providing the service
                    to the merchant during the six (6) months preceding the date
                    on which the claim arose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
