import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Components/Home/Index";
import Fees from "./Components/Fees/Fees";
import Plugins from "./Components/Plugins/Plugins";
import Coins from "./Components/Coins/Coins";
import Login from "./Components/Coman/Login";
import Aboutus from "./Components/Aboutus/Aboutus";
import FAQ from "./Components/FAQ/FAQ";
import Dashboard from "./Dashboard/Dashboard";
import DepositList from "./Dashboard/DepositList/DepositList";
import SHistory from "./Dashboard/DepositList/History/SHistory";
import Account from "./Dashboard/Account";
import Withdrawal from "./Dashboard/Withdrawal/Withdrawal";
import WithdrawalList from "./Dashboard/Withdrawal/WithdrawalList";
import MyAllTeam from "./Dashboard/MyAllTeam";
import SelfCashBack from "./Dashboard/Reward/SelfCashBack";
import DirectSponsor from "./Dashboard/Reward/DirectSponsor";
import TeamBuilding from "./Dashboard/Reward/TeamBuilding";
import WorkingRoyalty from "./Dashboard/Reward/WorkingRoyalty";
import NonWorkingRoyalty from "./Dashboard/Reward/NonWorkingRoyalty";
import RankRewards from "./Dashboard/Reward/RankRewards";
import RoyaltyBooster from "./Dashboard/Reward/RoyaltyBooster";
import MagicBooster from "./Dashboard/Reward/MagicBooster";
import EarthBooster from "./Dashboard/Reward/EarthBooster";
import GlobalAuto from "./Dashboard/Reward/GlobalAuto";
import LuckyWinner from "./Dashboard/Reward/LuckyWinner";
import Network from "./Dashboard/Reward/Network";
import Income from "./Dashboard/Income";
import PoolEntry from "./Dashboard/PoolEntry";
import AutoPool from "./Dashboard/AutoPool";
import NewPoolEntry from "./Dashboard/NewPoolEntry";
import ComingSoon from "./Components/Coman/ComingSoon";
import TermsServices from "./Components/Terms-Services/TermsServices";
import PrivacyPolicy from "./Components/Privacy-Policy/PrivacyPolicy";
import RiskPolicy from "./Components/RiskPolicy/RiskPolicy";
import SalaryReward from "./Dashboard/Reward/SalaryReward";
import SloteHistory from "./Dashboard/SloteHistory";
import X1income from './Dashboard/Reward/X1income';
import MiningReward from './Dashboard/Reward/MiningReward';
import RankIncome from './Dashboard/Reward/RankIncome';
import GlobalVipReward from './Dashboard/Reward/GlobalVipReward';
import OverView from './Dashboard/OverView';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/fees" element={<Fees />} />
        <Route path="/plugins" element={<Plugins />} />
        <Route path="/coins" element={<Coins />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/terms-services" element={<TermsServices />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/risk-warning" element={<RiskPolicy />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/slots-purchase" element={<DepositList />} />
        <Route path="/slots-purchase/:link_ref_address" element={<DepositList />} />
        <Route path="/pool-entry" element={<NewPoolEntry />} />
        {/* <Route path="/pool-entry" element={<PoolEntry />} /> */}
        <Route path="/lucky-winner" element={<SHistory />} />
        <Route path="/account" element={<Account />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/withdrawal-history" element={<WithdrawalList />} />
        <Route path="/my-team" element={<MyAllTeam />} />
        <Route path="/magic-pool" element={<MagicBooster />} />
        <Route path="/earth-pool" element={<EarthBooster />} />
        <Route path="/global-pool" element={<GlobalAuto />} />
        <Route path="/1x-reward" element={<X1income />} />
        <Route path="/mining-reward" element={<MiningReward />} />

        <Route path="/cash-back" element={<SelfCashBack />} />
        <Route path="/slots-history" element={<SloteHistory />} />
        <Route path="/royalty-booster" element={<RoyaltyBooster />} />
        <Route path="/direct-referral" element={<DirectSponsor />} />
        <Route path="/community-building" element={<TeamBuilding />} />
        <Route path="/leadership-reward" element={<WorkingRoyalty />} />
        <Route path="/reprformance-reward" element={<NonWorkingRoyalty />} />
        <Route path="/vip-reward" element={<RankIncome />} />
        <Route path="/global-vip-reward" element={<GlobalVipReward />} />
        <Route path="/salary-reward" element={<SalaryReward />} />
        <Route path="/donation-fund" element={<Income />} />
        <Route path="/auto-pool" element={<AutoPool />} />
        <Route path="/overview" element={<OverView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;