import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      <footer className="footer-section">
        <div className="overlay pt-90 pb-90">
          <div className="container">
            <div className="row wrapper">
              <div className="col-xl-5 mb-3">
              <div className="single-area">
                  <a class="navbar-brand" href="/">
                    <img
                      src="assets/img/new-img/logo.svg"
                      className="logo"
                      style={{ width: "80%" }}
                      alt="logo"
                    />
                  </a>
                  <p className="my-3">Subscribe to our newsletter & get latest updates directly into your email! </p>
                  {/* <form action="#" className="pe-0 pe-md-5">
                    <div className="subscribe d-flex px-">
                      <input type="email" placeholder="Your Email Address" />
                      <button>
                        <img src="assets/img/send.png" alt="icon" />
                      </button>
                    </div>
                  </form> */}
                  <div className="social">
                    <ul className="d-flex">
                      <li>
                        <a href="javascript:void(0)">
                          <img src="assets/img/facebook.png" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/dollarpay_)" target="_blank">
                          <img src="assets/img/twitter.png" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/dollar_pay/" target="_blank">
                          <img src="assets/img/instagram.png" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <img src="assets/img/linkedin.png" alt="icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-7">
           <div className="row justify-content-end">
           <div className="col-lg-4 col-md-6">
                <div className="single-area">
                  <h5>Menu</h5>
                  <ul className="items">
                    <li>
                      <a href="/coins">Supported Currencies</a>
                    </li>
                    <li>
                      <HashLink smooth to='/#join-our-affiliate-program'>Affiliation Program</HashLink>
                    </li>
                    <li>
                      <HashLink smooth to='/#DeFiiPe-work'>How Does It Work?</HashLink>
                    </li>
                    <li>
                      <HashLink smooth to='/#features'> Features</HashLink>
                    </li>
                   
                    <li>
                      <HashLink smooth to='/#testimonials'> Testimonials</HashLink>
                    </li>
                  
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-area">
                  <h5>About</h5>
                  <ul className="items">
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/faq"> FAQ</a>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-area">
                  <h5>Support</h5>
                  <ul className="items">
                   
                    <li>
                      <a href="/terms-services"> Terms of Service</a>
                    </li>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/risk-warning"> Risk Warning</a>
                    </li>
                   
                  </ul>
                </div>
              </div>
           </div>
              </div>
           
            
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="main-content">
              <div className="row d-flex align-items-center">
                <div className="col-lg-6 col-md-8 cus-order d-flex justify-content-md-start justify-content-center">
                  <div className="left-area">
                    <p className="mdr">
                      DeFiiPe © {year}. ALL RIGHTS RESERVED. 
                    
                      {/* <a href="https://themeforest.net/user/pixelaxis">
                        Pixelasis
                      </a> */}
                    </p>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-4 d-flex justify-content-md-end justify-content-center">
                  <div className="right-area">
                    <select name="lang">
                      <option value="en">English</option>
                      <option value="bn">Bangla</option>
                      <option value="hn">Hindi</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
