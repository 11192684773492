import React from "react";

export default function RiskPolicyMain() {
  return (
    <div>
      <div className="dashboard-section transactions recipients p-0 m-0 w-100">
        <div className="overlay pt-120 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-header mb-4">
                  <h4 className="title text-center">
                    Risk <span className="text-Purple">  warning </span>
                  </h4>
                  <h6 className="text-black m-2 mt-3 ">
                      Disclaimer and Risk Warnings
                    </h6>
                    <p>
                      The Website, Content, and Services are provided without
                      any guarantees, conditions as to its accuracy, quality,
                      and fit for a particular purpose or need. The Company does
                      not guarantee that the Website and Services are
                      error-free, or will operate without interruption. We are
                      not in charge of any substance of outside sites that are
                      connected to our pages.
                    </p>
                    <p className="mt-2">
                      Any conclusions and remarks communicated on this site are
                      not the perspectives held all through DeFiiPe. No
                      Partner, agent, or worker of DeFiiPe acknowledges risk
                      for any immediate or noteworthy misfortune emerging from
                      the utilization of this archive or its services. This site
                      ought to work according to Singapore’s Payment Services
                      Act 2019 (PSA).
                    </p>
                    <p className="mt-2">
                      DeFiiPe shall not be liable for any events that may
                      include civil, criminal, and administrative actions,
                      Server hack or damage that may arise, if such use is
                      forbidden or otherwise limited in your country, or any new
                      law regarding that comes in power or adapted by the
                      authorities, failure of power supply or communication
                      system or other similar circumstances that prevent the
                      Parties from the proper fulfillment of their obligations
                      hereunder. And we are also not liable for the use or
                      inability to use the Website and Services by anyone. We
                      are not liable for any loss of funds due to cross chain
                      transfer, internal transaction(in ethereum or Binance
                      smart chain) or cross token transfer made by you or your
                      client. In such a case the fund will not recover in any
                      case.
                    </p>
                    <p className="mt-2">
                      Any misuse of the services or the platforms by (“you” or
                      “clients”) will be considered as an act of violation
                      against the DeFiiPe terms, so, therefore, legal action
                      might be taken against you as per Singapore law and order.
                    </p>
                    <p className="mt-2">
                      It would be ideal if you take note that the estimation of
                      ventures and the pay received from them may fall and you
                      may get back short of what you contributed. Past execution
                      isn't a manual for future execution. No speculation is
                      reasonable in all cases and if you have any questions as
                      to a venture's appropriateness then you should reach us.
                    </p>
                    <p className="mt-2">
                      We may process individual information identifying with you
                      as per the Personal Data Protection Act (PDPA) of
                      Singapore as revised and some other controls identifying
                      information security, regarding what we may do with such
                      information. You can know more about our privacy policy.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
